import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import JornImg from "../img/Foto-Jorn-1.jpg"

const JornPage = () => {
  return (
    <div>
      <Container  className="big_container">
      <Row className="custom-rows justify-content-md-center">
        <Col  md={4}>
        <Image src={JornImg} roundedCircle fluid  />
        </Col>
      </Row>
        <Row className="custom-rows">
          <Col>
          <h1>Gründer Jörn Eble</h1>
          <p>Jörns Mission verändert die Art und Weise, wie kleine und mittelständische Unternehmen risikolos wachsen und neue Märkte erschließen können.</p>
          <p>Vor 20 Jahren begann seine eigene Reise in die Welt der Gründer: mit einer Reiseberatung hat er als einer der ersten den Schritt in die Digitalisierung gewagt und Online-Reisebuchungen ermöglicht.</p>
          <p>„Ich wollte als Studienanfänger mal Professor werden. Heute bin ich froh, dass ich eine praktischere Laufbahn eingeschlagen habe. Ich will verstehen, wie Systeme funktionieren und wie sie verbessert werden können.</p>
          <p>Ich bin stolz darauf, dass wir über Jahrzehnte als eigenständiges Online-Reiseportal auf dem Markt erfolgreich waren. Wir haben nicht nur unsere eigene bedarfsgerechte Software entwickelt, sondern auch eine international funktionierende Struktur von Auslandsstandorten für die Rund-um-die Uhr-Betreuung aufgebaut. Das war nicht immer leicht und wir hatten mit vielen bürokratischen, finanziellen und strukturellen Problemen zu kämpfen. Bei einigen Dienstleistern waren wir oft nur eine Nummer und mussten langfristige Vertragsbindungen hinnehmen, weil es keine Alternativen auf dem Markt gab.</p>
          <p>Mit Swinging Agents machen wir einiges anders. Wir möchten mit Leistung überzeugen. Ein Geschäftsführer, der das Arbeiten mit uns erlebt, wird auch nicht weggehen. Unsere Kunden sollen wissen: Swinging Agents sind da, wenn ich sie brauche. Wenn sich jemand bei mir dafür bedankt, dass er endlich mal wieder den Kopf frei hat für das Kerngeschäft oder die Familie, dann haben wir alles richtig gemacht.</p>
          <p>Callcenter haben für viele einen negativen Touch. Den wollen wir ändern, denn unsere Mitarbeiter sind alle schon lange bei uns, fühlen sich wohl und lieben die verschiedenen Einsatzmöglichkeit. Sie arbeiten und leben überall auf der Welt, sprechen alle mindestens zwei Sprachen fließend und sind in ihren jeweiligen Zeitzonen flexibel einsetzbar.  </p>
          <p>Als der Euro eingeführt wurde, hatte ich mein erstes Geschäftsjahr grade beendet. Das hat mich damals buchhalterisch an meine Grenzen gebracht. Dazu hatte ich noch eine Software, die in DM geführt wurde und dann in Euro importiert wurde. Damit bin ich jetzt bei knapp 20 Jahren Berufserfahrung im eigenen Unternehmen in der Reisebranche. Ich denke manchmal, jetzt habe ich aber wirklich alles durch. Das stimmt natürlich nicht, aber ich habe wirkliche viele und vielfältige Erfahrungen gesammelt. Da waren bis zu 80 Mitarbeiter zu führen, Umsätze im 7stelligen Bereich verwaltet.</p>
          <p>Wenn ich eine Sache gelernt habe, dann ist es die Zeit fürs Wesentliche zu haben und sich zu nehmen. Damit meine ich, sich als Unternehmer auf das eigene Kerngeschäft konzentrieren zu können und dürfen. Die Ablenkung durch Organisation, Strukturen und Prozessen sind groß – man muss das Rad ja auch nicht immer neu erfinden. Und genau dafür gibt es die Swinging Agents.</p>
        
          </Col>
          </Row>
        
      </Container>
    </div>
  );
};

export default JornPage;
