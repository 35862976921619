import React from "react";
import HomeCarousel from "../components/HomeCarousel";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CompanyImg from "../img/16025.webp"
import Image from 'react-bootstrap/Image';
const Home = () => {
  return (
    <div>
      <HomeCarousel />
      <Container fluid="md" className="big_container">
        <Row className="custom-rows">
          <Col  md={6}>
            <h1>Dein Unternehmen braucht Unterstützung?</h1>
            <p>
              Stapeln sich gerade eure Anfragen im Postfach? Klingelt das
              Telefon häufiger als ihr es annehmen könnt? Damit seid ihr nicht
              allein. Jedes Unternehmen erlebt Stoßzeiten, zu denen die
              regulären Mitarbeiter nicht mehr alles erledigen können. Urlaub
              oder Krankheit sorgen für zusätzliche Mehrbelastungen, die für
              Stress und unzufriedene Kunden sorgen können. Mit Swinging Agents
              kannst du einen Teil deines Kundenservices und der Bürotätigkeiten
              in solchen Peak-Zeiten ganz einfach auslagern.
              <br />
              Die kompetenten Service-Mitarbeiter sind erfahren im Umgang mit
              verschiedenen CRM und wickeln eure Kundenanfragen via Telefon,
              Mail, Chat und Social Media für euch ab. Dabei greifen sie eurem
              bestehenden Serviceteam in Stoßzeiten nicht nur bei Anfragen auf
              Deutsch unter die Arme, sondern auch bei englischen, spanischen
              und französischen Kundenkontakten.
              <br />
              Die Swinging Agents verbinden sich einfach direkt mit eurem CRM
              und helfen exakt da, wo ihr die Hilfe braucht. Wir arbeiten als
              Partner u.a. mit Greyhound Software zusammen. Dank der
              maßgeschneiderten Textbausteine, Signaturen und Vorlagen in
              Greyhound findet die Übernahme völlig nahtlos, ohne Einarbeitung
              und in einheitlichem Wording statt. So können wir absolut
              transparent, nachvollziehbar und easy unterstützen – und zwar
              völlig flexibel nach eurem Bedarf.
            </p>
          </Col>
          <Col  md={6}><div className="img-container">
            <Image src={CompanyImg} fluid />
          </div>
          </Col>
        </Row>
        <div>
        <h2 className="second-title">Wie wir helfen</h2>
      </div>
      <Row>
        <Col className="first-serv">
          <a href="/wie-wir-helfen/#backoffice">
            <div className="service-bl buro border_box overlay">
              <div className="col-overlay-content border_box">
                <p className="service-title">
                Büromanagement
                </p>
              </div>
            </div>
          </a>
        </Col>
      </Row>
      
      <Row>
        <Col className="left-serv" sm>
          <a href="/wie-wir-helfen/#erreichbarkeit">
            <div className="service-bl erreich border_box">
              <div className="col-overlay-content border_box">
                <p className="service-title">Erreichbarkeit</p>
              </div>
            </div>
          </a>
        </Col>
        <Col className="center-serv" sm>
          <a href="/wie-wir-helfen/#sprachen">
            <div className="service-bl sprachen border_box">
              <div className="col-overlay-content border_box">
                <p className="service-title">Sprachen</p>
              </div>
            </div>
          </a>
        </Col>
        <Col className="right-serv" sm>
          <a href="/wie-wir-helfen/#kosten-sparen">
            <div className="service-bl kosten border_box">
              <div className="col-overlay-content border_box">
                <p className="service-title">Kosten</p>
              </div>
            </div>
          </a>
        </Col>
      </Row>
      </Container>
    </div>
  );
};

export default Home;
