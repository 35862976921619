import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Contact from './pages/Contact';
import Help from './pages/Help';
import WhoWeAre from './pages/WhoWeAre';
import Impressum from './pages/Impressum';
import Datenschtz from './pages/Datenschtz';
import CollapsibleExample from './components/ResponsiveNavbar';
import Footer from './components/Footer';
import JornPage from './pages/JornPage';
import ReinerPage from './pages/ReinerPage';

const App = () => {
  return (
    <Router>
      <div>
        <CollapsibleExample />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/wie-wir-helfen" element={<Help />} />
          <Route path="/wer-wir-sind" element={<WhoWeAre />} />
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/datenschutz" element={<Datenschtz />} />
          <Route path="/kontakt" element={<Contact />} />
          <Route path="/gruender-joern-eble" element={<JornPage />} />
          <Route path="/gruender-reiner-sieper" element={<ReinerPage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
