import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../components/HomeCarousel.css";

const Datenschtz = () => {
  return (
    <div>
      <header id="header">
        <div className="intro-daten">
          <div className="overlay">
            <Container className="cont">
              <Row className="justify-content-md-center">
                <Col md="8" className="intern-intro-text">
                  <h1>Datenschtz</h1>
                  <a
                    className="button"
                    href="/kontakt"
                    style={{ "--color": "#6eff3e" }}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    kontaktiere uns
                  </a>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </header>
      <Container className="big_container">
        <Row className="custom-rows">
          <Col>
            <h2>Wer wir sind</h2>
            <p>
              Die Adresse unserer Website ist: https://smart-voice-connect.de/
            </p>
            <h2>
              Welche personenbezogenen Daten wir sammeln und warum wir sie
              sammeln
            </h2>
            <h3>Kommentare</h3>
            <p>
              Wenn Besucher Kommentare auf der Website schreiben, sammeln wir
              die Daten, die im Kommentar-Formular angezeigt werden, außerdem
              die IP-Adresse des Besuchers und den User-Agent-String (damit wird
              der Browser identifiziert), um die Erkennung von Spam zu
              unterstützen.
            </p>
            <p>
              Aus deiner E-Mail-Adresse kann eine anonymisierte Zeichenfolge
              erstellt (auch Hash genannt) und dem Gravatar-Dienst übergeben
              werden, um zu prüfen, ob du diesen benutzt. Die
              Datenschutzerklärung des Gravatar-Dienstes findest du hier:
              https://automattic.com/privacy/. Nachdem dein Kommentar
              freigegeben wurde, ist dein Profilbild öffentlich im Kontext
              deines Kommentars sichtbar
            </p>
            <h3>Medien</h3>
            <p>
              Wenn du ein registrierter Benutzer bist und Fotos auf diese
              Website lädst, solltest du vermeiden, Fotos mit einem
              EXIF-GPS-Standort hochzuladen. Besucher dieser Website könnten
              Fotos, die auf dieser Website gespeichert sind, herunterladen und
              deren Standort-Informationen extrahieren.
            </p>
            <h3>Kontaktformulare</h3>
            <h4>Cookies</h4>
            <p>
              Wenn du einen Kommentar auf unserer Website schreibst, kann das
              eine Einwilligung sein, deinen Namen, E-Mail-Adresse und Website
              in Cookies zu speichern. Dies ist eine Komfortfunktion, damit du
              nicht, wenn du einen weiteren Kommentar schreibst, all diese Daten
              erneut eingeben musst. Diese Cookies werden ein Jahr lang
              gespeichert.
            </p>
            <p>
              Falls du ein Konto hast und dich auf dieser Website anmeldest,
              werden wir ein temporäres Cookie setzen, um festzustellen, ob dein
              Browser Cookies akzeptiert. Dieses Cookie enthält keine
              personenbezogenen Daten und wird verworfen, wenn du deinen Browser
              schließt.
            </p>
            <p>
              Wenn du dich anmeldest, werden wir einige Cookies einrichten, um
              deine Anmeldeinformationen und Anzeigeoptionen zu speichern.
              Anmelde-Cookies verfallen nach zwei Tagen und Cookies für die
              Anzeigeoptionen nach einem Jahr. Falls du bei der Anmeldung
              „Angemeldet bleiben“ auswählst, wird deine Anmeldung zwei Wochen
              lang aufrechterhalten. Mit der Abmeldung aus deinem Konto werden
              die Anmelde-Cookies gelöscht.
            </p>
            <p>
              Wenn du einen Artikel bearbeitest oder veröffentlichst, wird ein
              zusätzlicher Cookie in deinem Browser gespeichert. Dieser Cookie
              enthält keine personenbezogenen Daten und verweist nur auf die
              Beitrags-ID des Artikels, den du gerade bearbeitet hast. Der
              Cookie verfällt nach einem Tag.
            </p>
            <h4>Eingebettete Inhalte von anderen Websites</h4>
            <p>
              Beiträge auf dieser Website können eingebettete Inhalte beinhalten
              (z. B. Videos, Bilder, Beiträge etc.). Eingebettete Inhalte von
              anderen Websites verhalten sich exakt so, als ob der Besucher die
              andere Website besucht hätte.
            </p>
            <p>
              Diese Websites können Daten über dich sammeln, Cookies benutzen,
              zusätzliche Tracking-Dienste von Dritten einbetten und deine
              Interaktion mit diesem eingebetteten Inhalt aufzeichnen, inklusive
              deiner Interaktion mit dem eingebetteten Inhalt, falls du ein
              Konto hast und auf dieser Website angemeldet bist.
            </p>
            <h4>Analysedienste</h4>
            <h2>Mit wem wir deine Daten teilen</h2>
            <p>
              Wenn du eine Zurücksetzung des Passworts beantragst, wird deine
              IP-Adresse in der E-Mail zur Zurücksetzung enthalten sein.
            </p>
            <p>
              Wir sammeln Informationen über Besucher, die Kommentare auf
              Websites, die unseren Akismet Anti-Spam Service benutzen,
              hinterlassen. Das Ausmaß an Informationen, die wir sammeln, hängt
              davon ab, wie der Benutzer seine Website eingestellt hat.
              Normalerweise beinhalten diese Daten die IP-Adresse des Benutzers,
              den User Agent, Referrer und Website-URL (sowie die Informationen,
              die der Benutzer direkt eingegeben hat wie z.B. Namen,
              Benutzernamen, E-Mail-Adresse und den Kommentar selbst).
            </p>
            <h2>Wie lange wir deine Daten speichern</h2>
            <p>
              Wenn du einen Kommentar schreibst, wird dieser inklusive Metadaten
              zeitlich unbegrenzt gespeichert. Auf diese Art können wir
              Folgekommentare automatisch erkennen und freigeben, anstatt sie in
              einer Moderations-Warteschlange festzuhalten.
            </p>
            <p>
              Für Benutzer, die sich auf unserer Website registrieren, speichern
              wir zusätzlich die persönlichen Informationen, die sie in ihren
              Benutzerprofilen angeben. Alle Benutzer können jederzeit ihre
              persönlichen Informationen einsehen, verändern oder löschen (der
              Benutzername kann nicht verändert werden). Administratoren der
              Website können diese Informationen ebenfalls einsehen und
              verändern.
            </p>
            <h2>Welche Rechte du an deinen Daten hast</h2>
            <p>
              Wenn du ein Konto auf dieser Website besitzt oder Kommentare
              geschrieben hast, kannst du einen Export deiner personenbezogenen
              Daten bei uns anfordern, inklusive aller Daten, die du uns
              mitgeteilt hast. Darüber hinaus kannst du die Löschung aller
              personenbezogenen Daten, die wir von dir gespeichert haben,
              anfordern. Dies umfasst nicht die Daten, die wir aufgrund
              administrativer, rechtlicher oder sicherheitsrelevanter
              Notwendigkeiten aufbewahren müssen.
            </p>
            <h2>Wohin wir deine Daten senden</h2>
            <p>
              Besucher-Kommentare könnten von einem automatisierten Dienst zur
              Spam-Erkennung untersucht werden.
            </p>
            <h2>
              Deine Kontakt-Informationen für datenschutzrelevante Anliegen
            </h2>
            <p> SVC Smart Voice Connect GmbH</p>

            <p>Klixstr. 31c</p>

            <p>13403 Berlin</p>

            <p>Handelsregister: HRB 266388 B</p>

            <p>Registergericht: Amtsgericht Charlottenburg</p>

            <p>
              <b>Vertreten durch:</b>
            </p>

            <p>Jörn Eble</p>

            <p>Reiner Sieper</p>

            <p>
              <b>Kontakt</b>
            </p>

            <p>Telefon: +49 (030) 629 30 20 50</p>

            <p>Courriel : kontakt@smart-voice-connect.com</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Datenschtz;
