import React from "react";
import Carousel from "react-bootstrap/Carousel";
import SliderImage1 from "../img/slider-img1.webp";
import SliderImage2 from "../img/slider-img2.webp";
import SliderImage3 from "../img/slider-img3.webp";
import "./HomeCarousel.css";
const HomeCarousel = () => {
  return (
    <Carousel>
      <Carousel.Item>
        <img className="d-block w-100" src={SliderImage1} alt="First slide" />
        <div className="overlay">
          <Carousel.Caption>
            <h1>Lerne uns jetzt kennen</h1>
            <a className="button" href="/kontakt" style={{'--color':'#6eff3e'}}>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    kontaktiere uns
  </a>
          </Carousel.Caption>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={SliderImage2} alt="Second slide" />
        <div className="overlay">
          <Carousel.Caption>
            <h1>Lerne uns jetzt kennen</h1>
            <a className="button" href="/kontakt" style={{'--color':'#6eff3e'}}>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    kontaktiere uns
  </a>
          </Carousel.Caption>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={SliderImage3}
          alt="Third slide"
        />
        <div className="overlay">
          <Carousel.Caption>
            <h1>Lerne uns jetzt kennen</h1>
            <a className="button" href="/kontakt" style={{'--color':'#6eff3e'}}>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    kontaktiere uns
  </a>
          </Carousel.Caption>
        </div>
      </Carousel.Item>
    </Carousel>
  );
};

export default HomeCarousel;
