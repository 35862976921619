import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import headsetLogo from '../img/headset-fill.svg';

function CollapsibleExample() {
  return (
    <Navbar collapseOnSelect sticky="top" expand="lg" bg="dark" data-bs-theme="dark">
    <Container>
      <Navbar.Brand href="/"> <img
            src={headsetLogo}
            alt="Headset Logo"
            width="30"
            height="30"
            className="d-inline-block align-top"
          />smart-voice-connect</Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav" >
      <Nav className="me-auto">
        
      </Nav>
      <Nav >
        <Nav.Link href="/">Home</Nav.Link>
        <Nav.Link href="/wie-wir-helfen">Wie wir helfen</Nav.Link>
        <Nav.Link href="/wer-wir-sind">Wer wir sind</Nav.Link>
        <Nav.Link href="/kontakt">Kontakt</Nav.Link>
      </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
  );
}

export default CollapsibleExample;