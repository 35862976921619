import React from 'react';
import { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Form, Button } from 'react-bootstrap';

const Contact = () => {
const [name, setName]= useState("");
const [phone, setPhone]= useState("");
const [email, setEmail]= useState("");
const [help, setHelp]= useState("");
const [check,setCheck]= useState(false);

const handleSubmit = async (event) => {
  event.preventDefault();

  try {
    

    const htmlBody = `
    <p><b>Name:</b>${name}</p>
    <p><b>Email:</b>${email}</p>
    <p><b>Telefonnummer:</b>${phone}</p>
    <p><b>Wie können wir helfen?:</b>${help}</p>

  `;
    const response = await fetch("/send-email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        to: "rami.gharbi@swinging-agents.de",
        subject: "Kontakt",
        html: htmlBody,
      }),
    });

    if (response.ok) {
      setEmail("");
      setName("");
      setPhone("");
      setHelp("");
      setCheck(false)
      // window.grecaptcha.reset();
    } else {
      alert('E-Mail konnte nicht gesendet werden. Versuchen Sie es erneut.');
    }
  } catch (error) {
    alert('Fehler beim Senden der E-Mail. Versuchen Sie es erneut.')
    console.error("Error sending email:", error.message);
  } 
};
  return (
    <div>
      <header id="header">
        <div className="intro-contact">
          <div className="overlay">
            <Container className="cont">
              <Row className="justify-content-md-center">
                <Col md="8" className="intern-intro-text">
                  <h1>Kontakt</h1>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </header>
      <Container  className="big_container">
        <Row>
          <Col>
          <div style={{textAlign:"center"}}>
          <h2>Du erreichst uns unter</h2>
          <p>030-629 30 20 50</p>
          <p>kontakt@smart-voice-connect.com</p>
          </div>
          </Col>
        </Row>
        <Row className="custom-rows">
          <Col>
          <div className="form-box">
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formName">
          <Form.Label>Name</Form.Label>
          <Form.Control 
          type="text" 
          placeholder="Gib deinen Namen ein"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
           />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control 
          type="email" 
          placeholder="Geben sie ihre E-Mail Adresse ein" 
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
            />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formPhone">
          <Form.Label>Telefonnummer</Form.Label>
          <Form.Control 
          type="text" 
          placeholder="Gib deine Telefonnummer ein" 
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          required
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formTextarea">
          <Form.Label>Wie können wir helfen?</Form.Label>
          <Form.Control 
          as="textarea" 
          rows={3} 
          value={help}
            onChange={(e) => setHelp(e.target.value)}
            required
            />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formCheckbox">
          <Form.Check 
          type="checkbox" 
          label="Ich habe die Datenschutzerklärung gelesen und akzeptiert." 
          checked={check}
            onChange={(e) => setCheck(e.target.checked)}
            required
            />
        </Form.Group>

        <Button variant="primary" type="submit">
          Absenden
        </Button>
      </Form>
    </div>
          </Col>
          </Row>
        
      </Container>
    </div>
  );
};

export default Contact;
