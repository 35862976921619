import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Image1 from "../img/languages.webp";
import Image2 from "../img/buroo.webp";
import Image3 from "../img/1009-min.jpg";
import Image4 from "../img/kosten-bl.webp"
const Help = () => {
    const location = useLocation();

    useEffect(() => {
      if (location.hash) {
        const id = location.hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, [location]);
  return (
    <div>
      <header id="header">
        <div className="intro-help">
          <div className="overlay">
            <Container className="cont">
              <Row className="justify-content-md-center">
                <Col md="8" className="intern-intro-text">
                  <h1>Wie wir helfen</h1>
                  <a className="button" href="/kontakt" style={{'--color':'#6eff3e'}}>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    kontaktiere uns
  </a>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </header>
      <Container className="big_container">
        <Row className="custom-rows intern-rows">
          <Col md={6}>
            <h1 id="erreichbarkeit">Erreichbarkeit</h1>
            <p>
              <u>
                Steigerung der Erreichbarkeit: Wir bieten dir telefonischen und
                schriftlichen Support ohne dauerhafte Personalkosten, wenn es
                nötig ist. Dabei richten wir uns ganz nach deinenWünschen und
                Öffnungszeiten.
              </u>
            </p>

            <p>
              Du kennst sicher die Probleme: Das Arbeitsvolumen schwankt, wenn
              am meisten los ist, wollen Mitarbeiter Urlaub haben oder sind
              krank und du würdest gerne deinen Kunden einen Service bieten, den
              Du mal wieder nicht bieten kannst.
            </p>

            <p>
              Im Onlinehandel kommen bis zu 50% der Buchungen und Calls erst
              nach 16 Uhr rein. Mitarbeiterinnen und Mitarbeiter arbeiten aber
              nur selten gerne abends bzw. werden mit Zuschüssen dazu motiviert,
              was die Arbeit teurer macht. Unsere Mitarbeiterinnen und
              Mitarbeiter arbeiten an ihrem jeweiligen Standort in verschiedenen
              Zeitzonen zu den Zeiten, wo sie gerne arbeiten möchten und wir
              können Dir unabhängig von der Uhrzeit motivierte Arbeitskräfte zu
              einem vernünftigen Preis anbieten. Wenn Du also abends länger oder
              sogar rund um die Uhr erreichbar sein willst, bist Du bei uns
              richtig.
            </p>

            <p>
              {" "}
              Ob wir dabei unsere Mitarbeiterinnen und Mitarbeiter in Deine
              bestehende Struktur integrieren oder Dir Dein eigenes Team
              aufbauen, kannst Du entscheiden. Ebenso ob Du dedizierte
              Mitarbeiter haben möchtest oder nur auf Kapazitäten zurückgreifen
              willst, wenn Du sie brauchst.
            </p>

            <p>
              Häufig sind Engpässe langfristig planbar z.B. zur Weihnachtszeit
              oder als Urlaubs- oder Schwangerschaftsvertretung. Aber auch bei
              kurzfristigen Peaks oder zu Pausenzeiten übernehmen wir gerne den
              Überlauf. Wenn Du jetzt gerade kurzfristig Bedarf hast, können wir
              Dir innerhalb von Stunden oder Tagen ein Angebot machen und mit
              Dir starten.
            </p>
          </Col>
          <Col md={6}>
            <div className="img-container">
              <Image src={Image3} fluid />
            </div>
          </Col>
        </Row>
        <Row className="custom-rows intern-rows">
          <Col md={6}>
            <div className="img-container">
              <Image src={Image2} fluid />
            </div>
          </Col>
          <Col md={6}>
            <h1 id="backoffice">Backoffice</h1>

            <p>
              Support für DeinBackoffice: Dein Büro ist nicht immer besetzt oder
              Du bist vor Ort bei Kunden? Mit uns wird Dein Kunde trotzdem
              persönlich betreut.
            </p>
            <p>
              Unterstützung bei Personalengpässen und Urlaubszeiten: Dein Kunde
              möchte auch zu Urlaubszeiten optimal betreut werden. Wir
              unterstützen Dich kurzfristig bei Deinem Tagesgeschäft und
              Standardanliegen.
            </p>
          </Col>
        </Row>
        <Row className="custom-rows intern-rows">
          <Col md={6}>
            <h1 id="sprachen">Sprachen</h1>
            <p>
              <u>
                Unsere Mitarbeiter sprechen, schreiben und denken fließend in
                verschiedenen Sprachen.
              </u>
            </p>

            <p>
              Bloß weil jemand die deutsche Sprache spricht, heißt es noch
              nicht, dass er mich versteht. Am Telefon haben wir aber nicht nur
              die Erwartung, dass mich jemand akustisch versteht, sondern auch
              dass er sich in mich hineinversetzen kann und mir mit meinem
              Problem hilft.
            </p>

            <p>
              Viele unserer deutschsprachigen Mitarbeiterinnen und Mitarbeiter
              sind komplett zweisprachig, sind in Deutschland geboren und ins
              Ausland gezogen und haben dort die Sprache des Landes fließend
              gelernt. Und wir haben Natives aus vielen anderen Ländern, die
              chinesisch, indisch, französisch, portugiesisch, russisch oder
              spanisch sprechen. Englisch sprechen natürlich alle.
            </p>

            <p>
              {" "}
              Wenn Du ins Ausland expandieren willst, können wir Dich gerne mit
              einzelnen Mitarbeitern unterstützen oder Dir ein dediziertes Team
              aufbauen. Auf Wunsch organisieren wir Dir auch eine eigenständige
              Firma oder einen eigenständigen Standort. Jedes Land hat seine
              Besonderheiten und Fallstricke, die sich erst in der Praxis
              herausstellen, in vielen Ländern haben wir aber bereits
              Erfahrungen gesammelt. Gerne helfen wir Dir auch bei der
              Personalsuche im Ausland.
            </p>
          </Col>
          <Col md={6}>
            <div className="img-container">
              <Image src={Image1} fluid />
            </div>
          </Col>
        </Row>
        <Row className="custom-rows intern-rows">
          <Col md={6}>
            <div className="img-container">
              <Image src={Image4} fluid />
            </div>
          </Col>
          <Col md={6}>
            <h1 id="kosten-sparen">Kosten sparen</h1>

            <p>
              <u>
                Gute Qualität hat immer ihren Preis. Aber die meisten
                Unternehmen haben einen hohen Wettbewerbsdruck und müssen auf
                die Kosten schauen. Wir helfen Dir dabei.
              </u>
            </p>
            <p>
              Wir finden den Mindestlohn in Deutschland gut. Gute Arbeit muss
              fair bezahlt werden. Und der Mindestlohn reicht in Deutschland bei
              hohen Lebenshaltungskosten häufig nicht zum Leben. Wenn man
              hingegen nur den Mindestlohn bzw. schlecht bezahlt, erhält man
              keine motivierten Mitarbeiter und kann diese nicht langfristig
              binden. Dies macht sich wiederum in der Qualität bemerkbar.
            </p>
            <p>
              Was liegt daher näher, als sich auch Mitarbeiter im Ausland zu
              suchen? Wir zahlen im Ortsvergleich überdurchschnittlich gut,
              finden daher Mitarbeiter, die gerne für uns und unsere Kunden
              arbeiten und langfristig bei uns bleiben. Dabei achten wir auf
              hohe fachliche Qualität und professionelles Verhalten am Telefon,
              nichts ist schlimmer als ein outgesourctes Callcenter, nur um
              Kosten zu sparen.
            </p>
            <p>
              Wir haben schon in einigen Ländern eine eigene Firma gegründet
              oder ein eigenes Callcenter aufgebaut, z.B. in Argentinien,
              Brasilien, China, Frankreich, Griechenland, Spanien, Ukraine. Wir
              haben Erfahrungen gesammelt, was gut und was schlecht
              funktioniert, welche Tätigkeiten in welchen Ländern besonders
              schnell erledigt werden können, wo Menschen gut im Büro und wo sie
              gut zu Hause arbeiten. Wir haben gelernt, welche Tücken das
              Arbeitsrecht in welchen Ländern bereithält und wie teuer
              Abfindungen sein können.
            </p>
            <p>
              Was auch immer Du im Ausland brauchst, entweder wir haben es schon
              oder wir entwickeln es für Dich.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Help;
