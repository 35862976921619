import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../components/HomeCarousel.css";

const Impressum = () => {
  return (
    <div>
      <header id="header">
        <div className="intro-impre">
          <div className="overlay">
            <Container className="cont">
              <Row className="justify-content-md-center">
                <Col md="8" className="intern-intro-text">
                  <h1>Impressum</h1>
                  <a
                    className="button"
                    href="/kontakt"
                    style={{ "--color": "#6eff3e" }}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    kontaktiere uns
                  </a>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </header>
      <Container className="big_container">
        <Row className="custom-rows">
          <Col>
            <h2>Angaben gemäß § 5 TMG</h2>
            <p>SVC Smart Voice Connect GmbH</p>
            <p>Französische Straße 12</p>
            <p>10117 Berlin</p>
            <p>Handelsregister: HRB 266388 B</p>
            <p>Registergericht: Amtsgericht Charlottenburg</p>
            <p>
              <b>Vertreten durch:</b>
            </p>
            <p>Jörn Eble</p>
            <p>Reiner Sieper</p>
            <p>
              <b>Kontakt</b>
            </p>
            <p>Telefon: +49 (030) 629 30 20 50</p>
            <p>E-Mail: kontakt@smart-voice-connect.com</p>
            <p>
              <b>Postadresse</b>
            </p>
            <p>Französische Straße 12</p>
            <p>10117 Berlin</p>
            <p>
              <b>Umsatzsteuer-ID</b>
            </p>
            <p>
              Umsatzsteuer-Identifikationsnummer gemäß § 27 a
              Umsatzsteuergesetz:
            </p>
            <p>DE307425516</p>
            <p>
              <b>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV</b>
            </p>
            <p>SVC Smart Voice Connect GmbH</p>
            <p>Französische Straße 12</p>
            <p>10117 Berlin</p>
            <p>
              <b>EU-Streitschlichtung</b>
            </p>
            <p>
              Die Europäische Kommission stellt eine Plattform zur
              Online-Streitbeilegung (OS) bereit:
            </p>
            <p>
              <a
                href="https://ec.europa.eu/consumers/odr"
                target="_blank"
                rel="noreferrer"
              >
                https://ec.europa.eu/consumers/odr
              </a>
              .
            </p>
            <p>Unsere E-Mail-Adresse finden Sie oben im Impressum</p>
            <p>
              <b>Verbraucherstreitbeilegung/Universalschlichtungsstelle</b>
            </p>
            <p>
              Wir sind nicht bereit oder verpflichtet, an
              Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
              teilzunehmen.
            </p>
            <p>
              <b>Haftung für Inhalte</b>
            </p>
            <p>
              Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene
              Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
              verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter
              jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde
              Informationen zu überwachen oder nach Umständen zu forschen, die
              auf eine rechtswidrige Tätigkeit hinweisen.
            </p>
            <p>
              Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
              Informationen nach den allgemeinen Gesetzen bleiben hiervon
              unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
              Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich.
              Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir
              diese Inhalte umgehend entfernen.
            </p>
            <p>
              <b>Haftung für Links</b>
            </p>
            <p>
              Unser Angebot enthält Links zu externen Websites Dritter, auf
              deren Inhalte wir keinen Einfluss haben.
            </p>
            <p>
              Deshalb können wir für diese fremden Inhalte auch keine Gewähr
              übernehmen. Für die Inhalte der verlinkten Seiten ist stets der
              jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die
              verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche
              Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum
              Zeitpunkt der Verlinkung nicht erkennbar.
            </p>
            <p>
              Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist
              jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
              zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
              derartige Links umgehend entfernen.
            </p>
            <p>
              <b>Urheberrecht</b>
            </p>
            <p>
              Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
              diesen Seiten unterliegen dem deutschen Urheberrecht. Die
              Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
              Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
              schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
              Downloads und Kopien dieser Seite sind nur für den privaten, nicht
              kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser
              Seite nicht vom Betreiber erstellt wurden, werden die
              Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
              Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
              Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
              entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
              werden wir derartige Inhalte umgehend entfernen.
            </p>
            <p>Bildnachweise:</p>
            <p>
              Alle verwendeten Fotos unterliegen der lizenzfreien Nutzung von
              Pixabay und Pexel, außer:
            </p>
            <p>Profilfoto Jörn Eble ©Jörn Eble</p>
            <p>Profilfoto Reiner Sieper ©Reiner Sieper</p>
            <p>Quelle:</p>
            <p>
              <a
                href="https://www.e-recht24.de/impressum-generator.html"
                target="_blank"
                rel="noreferrer"
              >
                https://www.e-recht24.de/impressum-generator.html
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Impressum;
