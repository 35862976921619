import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import ReinerImg from "../img/SharedScreenshot.jpg"

const ReinerPage = () => {
  return (
    <div>
      <Container className="big_container">
      <Row className="custom-rows justify-content-md-center">
        <Col md={4}>
        <Image src={ReinerImg} roundedCircle fluid  />
        </Col>
      </Row>
        <Row className="custom-rows">
          <Col>
          <h1>Gründer Reiner Sieper</h1>
          <p>Reiner weiß aus über 20 Jahren Erfahrung, wie man Kundensupport macht und Projekte erfolgreich abschließt.</p>
          <p>„Ich arbeite seit Jahren mit Mitarbeitern aus allen Ecken der Welt. Ich kenne die Herausforderungen und Probleme, die sich ergeben, wenn man plötzlich mit Menschen zusammenarbeitet, die man nicht mehr sieht, weil sie in anderen Büros sitzen. Da braucht es neue Arbeitsprozesse oder andere Kommunikationsideen, so dass der Kunde sich auch weiter gut und sicher aufgehoben fühlt</p>
          <p>Wir sind eben kein klassisches Callcenter, sondern übernehmen Supportaufgaben aller Art: vom telefonischen Erstkontakt über Mailbearbeitung oder Onlineablagen strukturieren.</p>
          <p>In Deutschland ist die Mitarbeiterfindung für einzelne Bereiche mittlerweile wirklich schwierig geworden– da haben wir einen großen Vorsprung. Unser internationales Netzwerk besteht aus Mitarbeitern, die wir alle persönlich kennen und schätzen. Ich weiß, welche Qualitäten und Kompetenzen jeder einzelne hat und wie er oder sie eingesetzt werden kann. Unsere Kunden bekommen die Mitarbeiter, die sie am besten unterstützen können – sei es sprachlich, zeitlich oder mit Fachwissen um bestimmte Prozesse im Backoffice. Unsere Swinging Agents sind multikulti, d.h. Kulturen, die sich vielleicht in der Welt nicht verstehen, aber bei uns und unseren Kunden schon.“</p>
          </Col>
        </Row>
        
      </Container>
    </div>
  );
};

export default ReinerPage;